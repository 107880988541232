import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ServicesOne = () => {
  return (
    <section className="solutions-area bg-f1f8fb pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 pb-5">
            <p>
              HYDRA provides easy to install & intuitive wallet clients for
              different operating systems. The following are very detailed
              guides of how to install and use a wallet client.
            </p>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className="mx-auto"
                  layout="fixed"
                  width={80}
                  height={80}
                  src="../../assets/images/icons/windows-os.png"
                  alt="Guide for Windows"
                />
              </div>
              <h3>
                <Link
                  to="https://docs.hydrachain.org/guide-for-windows"
                  target="_blank"
                >
                  Guide for Windows
                </Link>
              </h3>
              <p>Guide to setup your wallet on Windows operating system</p>

              <ul className="list-inline">
                <li className="list-inline-item">32 Bit</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-win32-setup-unsigned.exe"
                    target="_blank"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-windows"
                    target="_blank"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item">64 Bit</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-win64-setup-unsigned.exe"
                    target="_blank"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-windows"
                    target="_blank"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className="mx-auto"
                  layout="fixed"
                  width={80}
                  height={80}
                  src="../../assets/images/icons/linux-os.png"
                  alt=" Guide for Linux"
                />
              </div>

              <h3>
                <Link
                  to="https://docs.hydrachain.org/guide-for-linux"
                  target="_blank"
                >
                  Guide for Linux
                </Link>
              </h3>

              <p>Guide to setup your wallet on Linux operating system</p>

              <ul className="list-inline">
                <li className="list-inline-item">Ubuntu 18.04</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-ubuntu18.04-x86_64-gnu.zip"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-linux#ubuntu-18-04"
                    target="_blank"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item">Ubuntu 20.04</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-ubuntu20.04-x86_64-gnu.zip"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-linux"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className="mx-auto"
                  layout="fixed"
                  width={80}
                  height={80}
                  src="../../assets/images/icons/mac-os.png"
                  alt="Guide for MacOS"
                />
              </div>

              <h3>
                <Link
                  to="https://docs.hydrachain.org/guide-for-macos"
                  target="_blank"
                >
                  Guide for MacOS
                </Link>
              </h3>

              <p>Guide to setup your wallet on MacOS operating system.</p>

              <ul className="list-unstyled">
                <li>
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-macos"
                    target="_blank"
                  >
                    Guide
                  </Link>
                </li>
                <li>
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-macos.dmg"
                  >
                    Download
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className="mx-auto"
                  layout="fixed"
                  width={80}
                  height={80}
                  src="../../assets/images/icons/raspberry-pi.png"
                  alt="Guide for Raspberry PI"
                />
              </div>

              <h3>
                <Link
                  to="https://docs.hydrachain.org/guide-for-raspberry-pi"
                  target="_blank"
                >
                  Guide for Raspberry PI
                </Link>
              </h3>

              <p>
                How to install and operate your wallet on Raspberry PI device.
              </p>

              <ul className="list-inline">
                <li className="list-inline-item">Arm 32bit</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-arm-linux-gnueabihf.zip"
                    target="_blank"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-raspberry-pi"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item">Arm 64bit</li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://github.com/Hydra-Chain/node/releases/download/hydra_v0.20.7/hydra-0.20.7-aarch64-linux-gnu.zip"
                  >
                    Download
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    className="view-details-btn"
                    to="https://docs.hydrachain.org/guide-for-raspberry-pi#setup-for-64-bit-ubuntu-desktop-20-10-for-pi"
                  >
                    Guide
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4 ">
            <div
              className="single-solutions-box"
              style={{ background: "rgba(255, 255, 255, 0.7)" }}
            >
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className="mx-auto"
                  layout="fixed"
                  width={80}
                  height={80}
                  src="../../assets/images/icons/updating-wallet.png"
                  alt="Updating Wallet"
                />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/how-to-update-your-hydra-wallet">
                  Updating your wallet
                </Link>
              </h3>

              <p>Guide on how to update your wallet application.</p>

              <Link
                className="view-details-btn"
                to="https://docs.hydrachain.org/how-to-update-your-hydra-wallet"
              >
                View Guide
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
