import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import Guides from "../components/Wallet/Guides";
import { Helmet } from "react-helmet";
const Services = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Desktop Client | HydraChain</title>
        <link
          rel="canonical"
          href={`https://hydrachain.org/${location.pathname}`}
        />
      </Helmet>
      <Navbar />
      <PageBanner
        pageTitle="Desktop Client"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Desktop Client"
      />
      <Guides />
      <Footer />
    </Layout>
  );
};

export default Services;
